import React from "react";

import { setAttributes } from "../../../utils";
import Column from "../Column/Column";

const Row = ({ row, postType, modal }) => {
  const advancedSettings = row.advancedSettings;

  const defaultClass = "row";
  let attributes = setAttributes(defaultClass, advancedSettings);

  return (
    <div
      id={attributes.id}
      aria-label={attributes.ariaLabel}
      className={attributes.className}
      data-settings={attributes.settings}
      style={attributes.style}
    >
      {row.genesisLayouts.map((column, index) => {
        return (
          <Column
            key={index}
            column={column}
            postType={postType}
            modal={modal}
          />
        );
      })}
    </div>
  );
};

export default Row;
