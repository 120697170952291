import ProxyAccordion from "./Accordion";
import ProxyButton from "./Button";
import ProxyForm from "./Form";
import ProxyGallery from "./Gallery";
import ProxyHeading from "./Heading";
import ProxyIframe from "./Iframe";
import ProxyImage from "./Image";
import ProxyMap from "./Map";
import ProxyPosts from "./Posts";
import ProxySlider from "./Slider";
import ProxyTextEditor from "./TextEditor";

const proxies = {
  Accordion: ProxyAccordion,
  Button: ProxyButton,
  Form: ProxyForm,
  Gallery: ProxyGallery,
  Heading: ProxyHeading,
  Iframe: ProxyIframe,
  Image: ProxyImage,
  Map: ProxyMap,
  Posts: ProxyPosts,
  Slider: ProxySlider,
  TextEditor: ProxyTextEditor,
};

export default proxies;
