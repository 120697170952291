import React from "react";

import LoaderMap from "./ProxyLoaders/LoaderMap";

const ModuleBuilder = ({ module, postType, modal }) => {
  const moduleType = module.__typename;

  /**
   * Default component
   */
  const Default = () => (
    <div>
      In ModuleBuilder the mapping of this module is missing: {moduleType}
    </div>
  );

  /**
   * Mapping the __typename(s) to our components
   */
  const modules = {
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Accordion`]:
      "Accordion",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Button`]:
      "Button",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Form`]:
      "Form",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_ImageGallery`]:
      "Gallery",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Heading`]:
      "Heading",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Iframe`]:
      "Iframe",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Image`]:
      "Image",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_GoogleMap`]:
      "Map",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Posts`]:
      "Posts",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_Slider`]:
      "Slider",
    [`WPGraphQL_${postType}_Layoutbuildersections_GenesisSections_CustomSection_rows_GenesisLayouts_Column_ColumnModules_TextEditor`]:
      "TextEditor",
    page_default: Default,
  };

  /**
   * If module type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = modules[moduleType]
    ? LoaderMap[modules[moduleType]]
    : modules["page_default"];

  return <ComponentTag {...module} postType={postType} modal={modal} />;
};

export default ModuleBuilder;
