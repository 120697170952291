import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { setAttributes } from "../../../utils";
import ModuleBuilder from "../../../modules/ModuleBuilder";

const Column = ({ column, postType, modal }) => {
  const advancedSettings = column.advancedSettings;

  const defaultClass = column.cssClass
    ? ["col-xs", column.cssClass].join(" ")
    : "col-xs";
  let attributes = setAttributes(defaultClass, advancedSettings);

  return (
    <div
      id={attributes.id}
      aria-label={attributes.ariaLabel}
      className={attributes.className}
      data-settings={attributes.settings}
      style={attributes.style}
    >
      {advancedSettings.design.backgroundImage ? (
        <GatsbyImage
          image={
            advancedSettings.design.backgroundImage.imageFile.childImageSharp
              .gatsbyImageData
          }
          className={"section__background"}
          alt={advancedSettings.design.backgroundImage.altText}
          title={advancedSettings.design.backgroundImage.title}
          width={1920}
          height={1080}
          imgStyle={{
            objectPosition: advancedSettings.design.backgroundPosition,
          }}
          objectPosition={advancedSettings.design.backgroundPosition}
        />
      ) : null}
      <div className="box">
        {column.columnModules.map((module, index) => {
          return (
            <ModuleBuilder
              key={index}
              module={module}
              postType={postType}
              modal={modal}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Column;
